<template>
  <div>
    <div v-show="applicationNum === 0" style="width: 100%; float: left">
      <div
        style="
          width: 100%;
          margin-left: 3%;
          margin-bottom: 20px;
          float: left;
          text-align: left;
          font-size: 1.0125rem;
          margin-top: 30px;
        "
      >
        Please fill in the following table to create an application.
      </div>
      <Card
        style="
          width: 80%;
          float: left;
          margin-left: 3%;
          padding: 10px 5%;
          margin-top: 10px;
          margin-bottom: 50px;
        "
      >
        <Form :model="join" ref="formValidate" :rules="ruleValidate">
          <div :model="join" style="text-align: left">
            <div class="join_table_unit">
              <div
                style="
                  font-size: 1.025rem;
                  font-weight: 500;
                  display: inline;
                  padding-right: 15px;
                "
              >
                Project:
              </div>
              <span style="display: inline">{{ projectName }}</span>
            </div>

            <div class="join_table_unit">
              <div
                style="
                  font-size: 1.025rem;
                  float: left;
                  font-weight: 500;
                  display: inline;
                  padding-right: 15px;
                "
              >
                Related module:
              </div>
              <Select
                @on-change="compute_percentage"
                size="default"
                v-model="join.moduleList"
                multiple
                style="width: 260px"
              >
                <Option
                  v-for="item in module_list"
                  :value="item.value"
                  :key="item.value"
                  >{{ item.label }}
                </Option>
              </Select>
            </div>

            <div class="join_table_unit">
              <div
                style="
                  font-size: 1.025rem;
                  font-weight: 500;
                  display: inline;
                  padding-right: 15px;
                "
              >
                Related Code Percentage:
              </div>
              <span style="display: inline">{{ join.relatedPercentage }}%</span>
            </div>

            <div class="join_table_unit">
              <div
                style="
                  font-size: 1.025rem;
                  font-weight: 500;
                  display: inline;
                  padding-right: 15px;
                "
              >
                Estimated Code Percentage for Permission:
              </div>
              <InputNumber
                :max="100"
                v-model="join.estimatedPercentage"
                :formatter="(value) => `${value}%`"
                :parser="(value) => value.replace('%', '')"
              ></InputNumber>
            </div>

            <div class="join_table_unit">
              <div
                style="
                  font-size: 1.025rem;
                  font-weight: 500;
                  display: inline;
                  padding-right: 15px;
                "
              >
                Type:
              </div>
              <Select style="width: 200px" v-model="join.type">
                <Option value="fix bug" key="1">fix bug</Option>
                <Option value="add new feature" key="2">add new feature</Option>
              </Select>
            </div>

            <div v-if="join.type === 'add new feature'" class="join_table_unit">
              <FormItem prop="functionSummary">
                <div
                  style="
                    font-size: 1.025rem;
                    font-weight: 500;
                    padding-right: 15px;
                    margin-bottom: 5px;
                  "
                >
                  Function summary:
                </div>
                <Input
                  v-model="join.functionSummary"
                  maxlength="150"
                  show-word-limit
                  type="textarea"
                  placeholder="Enter something..."
                ></Input>
              </FormItem>
            </div>
            <div v-if="join.type === 'add new feature'" class="join_table_unit">
              <FormItem prop="userViewSescription">
                <div
                  style="
                    font-size: 1.025rem;
                    font-weight: 500;
                    padding-right: 15px;
                    margin-bottom: 5px;
                  "
                >
                  Description (user view):
                </div>
                <p style="font-size: 14px; color: darkgray">
                  Please provide a concrete description from the perspective of
                  users.
                </p>
                <p style="font-size: 14px; color: darkgray">
                  You should give some examples to illustrate this new feature.
                </p>
                <Input
                  v-model="join.userViewSescription"
                  type="textarea"
                ></Input
              ></FormItem>
            </div>
            <div v-if="join.type === 'add new feature'" class="join_table_unit">
              <FormItem prop="technicalPlan">
                <div
                  style="
                    font-size: 1.025rem;
                    font-weight: 500;
                    padding-right: 15px;
                    margin-bottom: 5px;
                  "
                >
                  Feature Technical Plan:
                </div>
                <p style="font-size: 14px; color: darkgray">
                  If you can't give technical plan,
                  <a v-on:click="call_for_detail">you can ask for more detail</a
                  >.
                </p>
                <Input v-model="join.technicalPlan" type="textarea"></Input
              ></FormItem>
            </div>
            <!-- <div class="join_table_unit">
                         <Radio v-model="read_tag">Radio</Radio>
                     </div>-->
            <div v-if="join.type === 'fix bug'" class="join_table_unit">
              <FormItem prop="bugDescription">
                <div
                  style="
                    font-size: 1.025rem;
                    font-weight: 500;
                    padding-right: 15px;
                    margin-bottom: 5px;
                  "
                >
                  Bug description:
                </div>
                <p style="font-size: 14px; color: darkgray">
                  You can gives a brief overview and don't leave out facts.
                </p>
                <Input v-model="join.bugDescription" type="textarea"></Input>
              </FormItem>
            </div>

            <div v-if="join.type === 'fix bug'" class="join_table_unit">
              <FormItem prop="environmentVersion">
                <div
                  style="
                    font-size: 1.025rem;
                    font-weight: 500;
                    padding-right: 15px;
                    margin-bottom: 5px;
                  "
                >
                  Environment version:
                </div>
                <p style="font-size: 14px; color: darkgray">
                  Environment description / version information
                </p>
                <Input v-model="join.environmentVersion" type="textarea"></Input
              ></FormItem>
            </div>

            <div v-if="join.type === 'fix bug'" class="join_table_unit">
              <FormItem prop="bugRouteCase">
                <div
                  style="
                    font-size: 1.025rem;
                    font-weight: 500;
                    padding-right: 15px;
                    margin-bottom: 5px;
                  "
                >
                  Bug route case:
                </div>
                <p style="font-size: 14px; color: darkgray">
                  One of the very best ways you can report a bug is by showing
                  it to us! Show us the same thing again and demonstrate the
                  thing that goes wrong
                </p>
                <Input v-model="join.bugRouteCase" type="textarea"></Input
              ></FormItem>
            </div>

            <div v-if="join.type === 'fix bug'" class="join_table_unit">
              <FormItem prop="expectedResults">
                <div
                  style="
                    font-size: 1.025rem;
                    font-weight: 500;
                    padding-right: 15px;
                    margin-bottom: 5px;
                  "
                >
                  Expected results:
                </div>
                <p style="font-size: 14px; color: darkgray"></p>
                <Input v-model="join.expectedResults" type="textarea"></Input
              ></FormItem>
            </div>

            <div v-if="join.type === 'fix bug'" class="join_table_unit">
              <div
                style="
                  font-size: 1.025rem;
                  font-weight: 500;
                  padding-right: 15px;
                  margin-bottom: 5px;
                "
              >
                Screenshot:
              </div>
              <div
                v-for="(items, index) in join.screenshotList"
                style="
                  width: 100px;
                  height: 100px;
                  float: left;
                  margin-right: 15px;
                  border: 1px solid darkgray;
                "
              >
                <!--<img :src="items.src" />-->
                <img :src="items" height="98" width="98" />
                <!--<div class="demo-upload-list-cover">
                                <Icon type="ios-eye-outline" @click.native="handleView(items.name)"></Icon>
                                <Icon type="ios-trash-outline" @click.native="handleRemove(items)"></Icon>
                            </div>-->
                <div
                  style="
                    text-align: center;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-weight: 500;
                    font-size: 0.83rem;
                  "
                >
                  {{ index + 1 }}
                </div>
              </div>
              <Upload
                ref="upload"
                :show-upload-list="false"
                :format="['jpg', 'jpeg', 'png']"
                :max-size="2048"
                :before-upload="handleBeforeUpload"
                multiple
                type="drag"
                action=""
                style="display: inline-block; width: 100px"
              >
                <div style="width: 100px; height: 100px; line-height: 100px">
                  <Icon type="ios-camera" size="25"></Icon>
                </div>
              </Upload>
            </div>

            <div v-if="join.type === 'fix bug'" class="join_table_unit">
              <FormItem prop="fixPlan">
                <div
                  style="
                    font-size: 1.025rem;
                    font-weight: 500;
                    padding-right: 15px;
                    margin-bottom: 5px;
                  "
                >
                  Fix plan:
                </div>
                <p style="font-size: 14px; color: darkgray">
                  If you don't have enough information to give fix plan, you can
                  apply for <a v-on:click="call_for_detail">more detail</a>.
                </p>
                <Input v-model="join.fixPlan" type="textarea"></Input
              ></FormItem>
            </div>

            <div class="join_table_unit">
              <FormItem prop="testCase">
                <div
                  style="
                    font-size: 1.025rem;
                    font-weight: 500;
                    padding-right: 15px;
                    margin-bottom: 5px;
                  "
                >
                  Test case description:
                </div>
                <p style="font-size: 14px; color: darkgray">
                  Please provide a formal description about changes after your
                  contribution !
                </p>
                <Input v-model="join.testCase" type="textarea"></Input
              ></FormItem>
            </div>
          </div>
        </Form>

        <Divider></Divider>
        <div style="margin-top: 20px">
          <Button type="primary" @click="submit">Submit</Button>
          <Modal
            v-model="modal1"
            title="Common Modal dialog box title"
            @on-ok="ok"
            @on-cancel="cancel"
          >
            <p>Confirm your application.</p>
          </Modal>
        </div>
      </Card>
    </div>
    <div v-show="applicationNum === 1" style="width: 100%; float: left">
      <div
        v-if="join.status === 'untreated'"
        style="
          padding-left: 3.2%;
          font-weight: 500;
          padding-top: 30px;
          text-align: left;
          width: 100%;
          float: left;
        "
      >
        <span
          style="
            float: left;
            color: #e9ab01;
            font-size: 1.015rem;
            font-weight: 500;
          "
          >Your application is under voting.
        </span>
      </div>
      <div
        v-if="join.status === 'pass'"
        style="
          padding-left: 3.2%;
          font-weight: 500;
          padding-top: 30px;
          text-align: left;
          width: 100%;
          float: left;
        "
      >
        <span
          style="
            float: left;
            color: forestgreen;
            font-size: 1.015rem;
            font-weight: 500;
          "
          >Your application has been passed. You should continue your
          application in the part 'Test Case'.
        </span>
      </div>

      <div
        v-if="join.status === 'refuse'"
        style="
          padding-left: 3.2%;
          font-weight: 500;
          padding-top: 30px;
          text-align: left;
          width: 100%;
          float: left;
        "
      >
        <span
          style="
            float: left;
            color: darkred;
            font-size: 1.015rem;
            font-weight: 500;
          "
          >Your application has been refused! You can try to edit your
          application.
        </span>
      </div>

      <Card
        style="
          width: 80%;
          float: left;
          margin-left: 3%;
          padding: 10px 5%;
          margin-top: 10px;
          margin-bottom: 50px;
        "
      >
        <div :model="join" style="text-align: left">
          <div class="join_table_unit">
            <div
              style="
                font-size: 1.025rem;
                font-weight: 500;
                display: inline;
                padding-right: 15px;
              "
            >
              Project:
            </div>
            <span style="display: inline">{{ projectName }}</span>
          </div>

          <div class="join_table_unit">
            <div
              style="
                margin-bottom: 10px;
                font-size: 1.025rem;
                float: left;
                width: 100%;
                font-weight: 500;
                display: inline;
                padding-right: 15px;
              "
            >
              Related module:
            </div>
            <Button
              v-for="items in this.join.moduleList"
              style="margin-right: 10px"
            >
              {{ items }}
            </Button>
          </div>

          <div class="join_table_unit">
            <div
              style="
                font-size: 1.025rem;
                font-weight: 500;
                display: inline;
                padding-right: 15px;
              "
            >
              Related Code Percentage:
            </div>
            <span style="display: inline">{{ join.relatedPercentage }}%</span>
          </div>

          <div class="join_table_unit">
            <div
              style="
                font-size: 1.025rem;
                font-weight: 500;
                display: inline;
                padding-right: 15px;
              "
            >
              Estimated Code Percentage for Permission:
            </div>
            <span>{{ join.estimatedPercentage }} %</span>
          </div>

          <div class="join_table_unit">
            <div
              style="
                font-size: 1.025rem;
                font-weight: 500;
                display: inline;
                padding-right: 15px;
              "
            >
              Type:
            </div>
            <p style="font-size: 1rem; font-weight: 400; display: inline">
              {{ join.type }}
            </p>
          </div>

          <div v-if="join.type === 'add new feature'" class="join_table_unit">
            <div
              style="
                font-size: 1.025rem;
                font-weight: 500;
                padding-right: 15px;
                margin-bottom: 5px;
              "
            >
              Function summary:
            </div>
            <p>{{ join.functionSummary }}</p>
          </div>
          <div v-if="join.type === 'add new feature'" class="join_table_unit">
            <div
              style="
                font-size: 1.025rem;
                font-weight: 500;
                padding-right: 15px;
                margin-bottom: 5px;
              "
            >
              Description (user view):
            </div>
            <p style="font-size: 14px; color: darkgray">
              Please provide a concrete description from the perspective of
              users.
            </p>
            <p style="font-size: 14px; color: darkgray">
              You should give some examples to illustrate this new feature.
            </p>

            <p>{{ join.userViewSescription }}</p>
          </div>
          <div v-if="join.type === 'add new feature'" class="join_table_unit">
            <div
              style="
                font-size: 1.025rem;
                font-weight: 500;
                padding-right: 15px;
                margin-bottom: 5px;
              "
            >
              Feature Technical Plan:
            </div>
            <p style="font-size: 14px; color: darkgray">
              If you can't give technical plan,
              <a :href="'/#/furtherInfo?id=' + projectId"
                >you can ask for more detail</a
              >.
            </p>

            <p>{{ join.technicalPlan }}</p>
          </div>
          <!-- <div class="join_table_unit">
                         <Radio v-model="read_tag">Radio</Radio>
                     </div>-->
          <div v-if="join.type === 'fix bug'" class="join_table_unit">
            <div
              style="
                font-size: 1.025rem;
                font-weight: 500;
                padding-right: 15px;
                margin-bottom: 5px;
              "
            >
              Bug description:
            </div>
            <p style="font-size: 14px; color: darkgray">
              You can gives a brief overview and don't leave out facts.
            </p>

            <p>{{ join.bugDescription }}</p>
          </div>

          <div v-if="join.type === 'fix bug'" class="join_table_unit">
            <div
              style="
                font-size: 1.025rem;
                font-weight: 500;
                padding-right: 15px;
                margin-bottom: 5px;
              "
            >
              Environment version:
            </div>
            <p style="font-size: 14px; color: darkgray">
              Environment description / version information
            </p>
            <p>{{ join.environmentVersion }}</p>
          </div>

          <div v-if="join.type === 'fix bug'" class="join_table_unit">
            <div
              style="
                font-size: 1.025rem;
                font-weight: 500;
                padding-right: 15px;
                margin-bottom: 5px;
              "
            >
              Bug route case:
            </div>
            <p style="font-size: 14px; color: darkgray">
              One of the very best ways you can report a bug is by showing it to
              us! Show us the same thing again and demonstrate the thing that
              goes wrong
            </p>
            <p>{{ join.bugRouteCase }}</p>
          </div>

          <div v-if="join.type === 'fix bug'" class="join_table_unit">
            <div
              style="
                font-size: 1.025rem;
                font-weight: 500;
                padding-right: 15px;
                margin-bottom: 5px;
              "
            >
              Expected results:
            </div>
            <p style="font-size: 14px; color: darkgray"></p>
            <p>{{ join.expectedResults }}</p>
          </div>

          <div v-if="join.type === 'fix bug'" class="join_table_unit">
            <div
              style="
                font-size: 1.025rem;
                font-weight: 500;
                padding-right: 15px;
                margin-bottom: 5px;
              "
            >
              Screenshot:
            </div>
            <div
              v-for="items in uploadList"
              style="
                width: 100px;
                height: 100px;
                float: left;
                margin-right: 15px;
                border: 1px solid darkgray;
              "
            >
              <!--<img :src="items.src" />-->
              <img src="../assets/func.jpg" height="98" width="98" />
              <!--<div class="demo-upload-list-cover">
                                <Icon type="ios-eye-outline" @click.native="handleView(items.name)"></Icon>
                                <Icon type="ios-trash-outline" @click.native="handleRemove(items)"></Icon>
                            </div>-->
              <div
                style="
                  text-align: center;
                  width: 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  font-weight: 500;
                  font-size: 0.83rem;
                "
              >
                {{ items.name }}
              </div>
            </div>
          </div>

          <div v-if="join.type === 'fix bug'" class="join_table_unit">
            <div
              style="
                font-size: 1.025rem;
                font-weight: 500;
                padding-right: 15px;
                margin-bottom: 5px;
              "
            >
              Fix plan:
            </div>
            <p style="font-size: 14px; color: darkgray">
              If you don't have enough information to give fix plan, you can
              apply for
              <a :href="'/#/furtherInfo?id=' + projectId">more detail</a>.
            </p>
            <p>{{ join.fixPlan }}</p>
          </div>

          <div class="join_table_unit">
            <div
              style="
                font-size: 1.025rem;
                font-weight: 500;
                padding-right: 15px;
                margin-bottom: 5px;
              "
            >
              Test case description:
            </div>
            <p style="font-size: 14px; color: darkgray">
              Please provide a formal description about changes after your
              contribution !
            </p>
            <p>{{ join.testCase }}</p>
          </div>
        </div>

        <Divider></Divider>
        <div style="margin-top: 20px">
          <Button type="primary" @click="edit">Edit</Button>
          <Modal
            v-model="modal2"
            title="Confirm"
            @on-ok="real_edit"
            @on-cancel="cancel"
          >
            <p>Your application will be update.</p>
          </Modal>
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
export default {
  name: "createJoin1",
  data() {
    return {
      update: false,
      creditPoint: 0,
      modal1: false,
      modal2: false,
      userName: "",
      projectName: "",
      projectId: "",
      userId: "",
      abstract: "",
      ownerNumber: 0,
      ownerLeast: 0,
      applicationType: 0,
      disabled: false,
      environment: "",
      read_tag: false,
      applicationNum: 0,
      ruleValidate: {
        functionSummary: [
          {
            required: true,
            message: "The content cannot be empty",
            trigger: "blur",
          },
        ],
        userViewSescription: [
          {
            required: true,
            message: "The content cannot be empty",
            trigger: "blur",
          },
        ],
        technicalPlan: [
          {
            required: true,
            message: "The content cannot be empty",
            trigger: "blur",
          },
        ],
        bugDescription: [
          {
            required: true,
            message: "The content cannot be empty",
            trigger: "blur",
          },
        ],
        environmentVersion: [
          {
            required: true,
            message: "The content cannot be empty",
            trigger: "blur",
          },
        ],
        bugRouteCase: [
          {
            required: true,
            message: "The content cannot be empty",
            trigger: "blur",
          },
        ],
        expectedResults: [
          {
            required: true,
            message: "The content cannot be empty",
            trigger: "blur",
          },
        ],
        fixPlan: [
          {
            required: true,
            message: "The content cannot be empty",
            trigger: "blur",
          },
        ],
        testCase: [
          {
            required: true,
            message: "The content cannot be empty",
            trigger: "blur",
          },
        ],
      },
      join: {
        id: "",
        bugDescription: "",
        bugRouteCase: "",
        environmentVersion: "",
        estimatedPercentage: 0,
        expectedResults: "",
        fixPlan: "",
        functionSummary: "",
        moduleList: [],
        //模块列表List《String》
        status: "",
        projectId: "",
        projectName: "",
        relatedPercentage: 0, //相关比例
        reply: "", //拒绝的理由
        screenshotList: [],
        technicalPlan: "",
        testCase: "", //测试用例描述
        type: "fix bug", //fix function
        userId: "", //用户ID
        userViewSescription: "", //截图地址列表
        username: "", //用户名
        //status: pass reject untreated
      },
      module_list: [
        {
          value: "print",
          label: "print",
          percentage: 6,
        },
        {
          value: "learn",
          label: "learn",
          percentage: 8,
        },
        {
          value: "check",
          label: "check",
          percentage: 10,
        },
        {
          value: "alert",
          label: "alert",
          percentage: 16,
        },
      ],
      columns1: [
        {
          title: "Type",
          key: "name",
        },
        {
          title: "Proportion",
          key: "value",
        },
      ],
      data1: [
        {
          name: "share to approve",
          value: 44,
        },
        {
          name: "share to reject ",
          value: 33,
        },
        {
          name: "pending",
          value: 23,
        },
      ],
      myChart: "",
      imgName: "",
      uploadList: [],
      visible: true,
      screenshot_tip:
        "You should upload at least 3 screenshots to show this bug.",
      end_date: "2021-03-14",
    };
  },
  methods: {
    Initial_data() {
      var Tself = this;
      Tself.projectId = this.$route.query.id;
      Tself.join.projectId = this.$route.query.id;
      Tself.userId = this.getCookie("user_id");
      Tself.join.userId = this.getCookie("user_id");
      Tself.join.username = this.getCookie("user_name");
      Tself.$axios({
        headers: {
          "Content-Type": "application/text",
        },
        method: "post",
        url: "/project/findById",
        data: Tself.projectId,
      })
        .then(function (response) {
          var data = response.data.data.items;
          Tself.environment = data.environment;
          Tself.projectName = data.name;
          Tself.join.projectName = data.name;
          Tself.abstract = data.abstractInfo;
          Tself.languages = data.languages;
          Tself.dependencies = data.dependencies;
          Tself.creationTime = data.creationTime;
          var tmpl = data.functionList;
          var pet = 0;
          if (tmpl.length !== 0) {
            pet = 100 / tmpl.length;
          }
          Tself.module_list = tmpl.map(function (obj) {
            return {
              value: obj,
              label: obj,
              percentage: pet,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });

      var userJoinVO = {
        projectId: Tself.projectId,
        userId: Tself.userId,
      };
      this.$axios({
        method: "post",
        url: "/join/findByUserIdAndProjectId",
        data: userJoinVO,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          var data = response.data.data.items;
          Tself.join.id = data.id;
          Tself.applicationNum = 1;
          Tself.join.bugDescription = data.bugDescription;
          Tself.join.bugRouteCase = data.bugRouteCase;
          Tself.join.environmentVersion = data.environmentVersion;
          Tself.join.estimatedPercentage = parseInt(data.estimatedPercentage);
          Tself.join.expectedResults = data.expectedResults;
          Tself.join.fixPlan = data.fixPlan;
          Tself.join.functionSummary = data.functionSummary;
          Tself.join.moduleList = data.moduleList;
          Tself.join.relatedPercentage = parseInt(data.relatedPercentage); //相关比例
          Tself.join.screenshotList = data.screenshotList;
          Tself.join.technicalPlan = data.technicalPlan;
          Tself.join.testCase = data.testCase; //测试用例描述
          Tself.join.type = data.type; //fix function
          Tself.join.userViewSescription = data.userViewSescription; //截图地址列表
          Tself.join.status = data.status;
        })
        .catch((error) => {
          console.log("You should commit a join application.");
          this.applicationNum = 0;
        });
    },
    ok() {
      //提交的逻辑！
      //如果已经有了那applicationNum必然不为0！
      //
      if (this.update === true) {
        var lo = this.join;
        console.log("-----------------lo");
        console.log(lo);
        this.$axios
          .put("/join/updateJoin", lo)
          .then((response) => {
            console.log(response);
            var data = response.data.data.items;
            console.log(data);
            this.applicationNum = data.count;
            this.join.id = data.id;
            this.$Message.success("OK!");
          })
          .catch((error) => {
            this.$Message.error(error.message);
          });
      } else {
        var lo = this.join;
        this.$axios
          .post("/join/addJoin", lo)
          .then((response) => {
            console.log(response);
            var data = response.data.data.items;
            this.applicationNum = data.count;
            this.$Message.success("OK!");
          })
          .catch((error) => {
            this.$Message.error(error.message);
          });
      }
    },
    cancel() {},
    submit() {
      this.$refs["formValidate"].validate((valid) => {
        if (valid) {
          this.modal1 = true;
        } else {
          this.$Message.error("Please imporve your applycation");
        }
      });
    },
    edit() {
      this.modal2 = true;
    },
    real_edit() {
      this.update = true; //标志进入修改状态
      this.applicationNum = 0;
    },

    join_check() {},
    call_for_detail() {
      this.$router.push("/furtherInfo?id=" + this.projectId);
    },
    compute_percentage() {
      this.join.relatedPercentage = 0;
      for (let i = 0; i < this.module_list.length; i++) {
        for (let j = 0; j < this.join.moduleList.length; j++) {
          if (this.join.moduleList[j] === this.module_list[i].value) {
            this.join.relatedPercentage =
              this.join.relatedPercentage + this.module_list[i].percentage;
          }
        }
      }
    },
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    handleBeforeUpload(file) {
      //似乎是要先uploadImage将得到的url作为参数
      let fd = new FormData();
      fd.append("image", file);
      this.$axios
        .post("/uploadImage", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log(response);
          this.join.screenshotList.push(response.data.data.filePath);
        })
        .catch((error) => {
          console.log(error);
          this.$Message.error(error.message);
        });

      this.uploadList.push(file);
      const check = this.uploadList.length < 2;
      if (!check) {
        this.$Notice.warning({
          title: "You should add more screenshot.",
        });
      }

      console.log(file);
      console.log(this.uploadList.length);
      return false;
    },
    handleView(name) {
      this.imgName = name;
      this.visible = true;
    },
    handleRemove(file) {
      const fileList = this.$refs.upload.fileList;
      this.$refs.upload.fileList.splice(fileList.indexOf(file), 1);
    },
  },
  mounted() {
    this.Initial_data();
  },
  updated() {},
};
</script>

<style scoped>
.join_main_container {
  width: 90%;
  margin-left: 5%;
  float: left;
  background-color: white;
}

.projectHeader {
  margin: 20px 0;
  width: 100%;
  height: 2rem;
  padding: 0 3.125rem;
  float: left;
}
.join_table_unit {
  padding-bottom: 40px;
  width: 100%;
  float: left;
}
.demo-upload-list {
  display: inline-block;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  margin-right: 4px;
}
</style>
